/* global gsap */

import { $, $$ } from './helper/utils';
import outline from './helper/outline';
// import inView from './helper/inView';
import { MOBILMEDIA } from './helper/constants';
import langSwitch from './components/langSwitch';

outline();
langSwitch();

let mobileNavLoaded = false;

function loadMobilenav() {
  if (mobileNavLoaded === true) {
    return;
  }

  import('./components/offcanvas')
    .then((offcanvas) => {
      const showMenuBtn = $('.show-menu');
      const [bar1, bar2, bar3] = showMenuBtn.children;
      const navigation = offcanvas.default($('.mobile-nav'), 'left');
      const tl = gsap.timeline({ paused: true });
      tl
        .to(bar1, { duration: 0.225, x: -4, y: 16, rotation: -45 }, '<')
        .to(bar2, { duration: 0.225, opacity: 0, scale: 0 }, '<')
        .to(bar3, { duration: 0.225, x: -8, y: -10, rotation: 45, scaleX: 1.333 }, '<');

      showMenuBtn.addEventListener('click', () => {
        navigation.toggle();

        if (tl.progress() > 0) {
          tl.reverse();
        } else {
          window.scrollTo(0, 0);
          tl.play();
        }
      }, { passive: true });
      mobileNavLoaded = true;
    })
    .catch((err) => {
      console.warn('Chunk offcanvas.js loading failed', err);
    });
}

export default function () {
  if (MOBILMEDIA.matches && mobileNavLoaded === false) {
    loadMobilenav();
  }

  MOBILMEDIA.addListener((e) => {
    if (e.matches) {
      if (mobileNavLoaded === false) loadMobilenav();
    }
  });

  if ($('.agegate') !== null && document.documentElement.classList.contains('hideag') === false) {
    import('./components/ageGate')
      .then((agegate) => agegate.default())
      .catch((err) => {
        console.warn(`Chunk agegate.js loading failed: ${err}`);
      });
  }

  if ($('.audio-player') !== null) {
    import('./components/audioPlayer/')
      .then((audioPlayer) => audioPlayer.default())
      .catch((err) => {
        console.warn(`Chunk audioPlayer/index.js loading failed: ${err}`);
      });
  }

  if ($('.pressreleases .grid') !== null) {
    import('./components/pressrelease')
      .then((pressrelease) => pressrelease.default())
      .catch((err) => {
        console.warn(`Chunk pressrelease.js loading failed: ${err}`);
      });
  }
  
  if ($('.cookie-policy') !== null) {
    import('./components/cookie.js').then((cookie) => {
      return cookie.init();
    }).catch((err) => {
      console.warn(`Chunk cookie.js loading failed: ${err}`);
    });
  }

  if ($$('.nav-tabs, [data-toggle="collapse"]').length) {
    const fadeTab = (e) => {
      const href = e.relatedTarget.getAttribute('href');
      gsap.fromTo(href, { duration: 0.3, opacity: 0 }, { opacity: 1, ease: 'linear' });
    };

    import('../libs/bs-4-native')
      .then((bsn) => {
        if ('bootstrapNative' in window === false) {
          window.bootstrapNative = bsn;
        }
        const tabs = $$('.nav-tabs .tab-link');
        for (let i = 0; i < tabs.length; i++) {
          const el = tabs[i];

          // fade in new tab content
          el.addEventListener('hide.bs.tab', fadeTab, false);
        }
      })
      .catch((err) => {
        console.warn('Chunk bs4-native.js loading failed', err);
      });
  }

  const radioTabsSelect = $('.radio-tabs-select select');
  if (radioTabsSelect !== null) {
    radioTabsSelect.addEventListener('change', function () {
      const el = $(`.tab-link[href="#${this.value}"]`);

      if (el === null) {
        return;
      }

      el.Tab.show();
    });
  }
}
