import { $$, addEventListenerAll } from '../helper/utils';

const langSelect = $$('.lang-switch > button');

export default function langSwitch() {
  addEventListenerAll(langSelect, 'click', function () {
    this.parentElement.classList.toggle('open');
  }, { passive: true });

  window.addEventListener('click', (e) => {
    for (let i = 0; i < langSelect.length; i++) {
      const el = langSelect[i].parentElement;
      if (e.target !== el && el.contains(e.target) === false) {
        el.classList.remove('open');
      }
    }
  });

  for (let i = 0; i < langSelect.length; i++) {
    const el = langSelect[i].parentElement;
    el.addEventListener('focusout', function () {
      this.classList.remove('open');
    });
  }
}
